import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { GetListOrderReferenceGuide, GetOrder } from '../action/order-reference-guide.action';
import { OrderService } from '@core/services/order-reference-guide.service';
import { tap } from 'rxjs';
import { Order } from '@core/interfaces/order-reference-guide.interface';
import { formatDate, formatDateWithoutTime, formatTime } from '@core/helper/request-time-window-helper';

export interface OrderReferenceGuideStateModel {
  orderList: Order[];
  order: Order | null;
  total: number | null;
}

@State<OrderReferenceGuideStateModel>({
  name: 'orderReferenceGuide',
  defaults: {
    orderList: [],
    order: null,
    total: null,
  },
})
@Injectable()
export class OrderReferenceGuideState {
  constructor(private orderService: OrderService) {}

  @Selector()
  static getOrderReferenceGuides(state: OrderReferenceGuideStateModel): Order[] {
    return state.orderList;
  }

  @Selector()
  static getOrder(state: OrderReferenceGuideStateModel): Order | null {
    return state.order;
  }

  @Selector()
  static getTotalOrderReferenceGuides(state: OrderReferenceGuideStateModel) {
    return state.total;
  }

  @Action(GetListOrderReferenceGuide)
  public getListOrder(
    ctx: StateContext<OrderReferenceGuideStateModel>,
    { pagination, filter }: GetListOrderReferenceGuide,
  ) {
    return this.orderService.getListOrder(pagination, filter).pipe(
      tap((res) => {
        const transformedOrders = res.data.getListOrder.result.map((order) => ({
          ...order,
          createdAt: formatDate(order.createdAt),
          deliveryDate: formatDateWithoutTime(order.deliveryDate),
          timeWindow: formatTime(order.timeWindow),
        }));
        ctx.patchState({
          orderList: transformedOrders,
          total: res.data.getListOrder.total,
        });
      }),
    );
  }

  @Action(GetOrder)
  public getOrder(ctx: StateContext<OrderReferenceGuideStateModel>, { id }: GetOrder) {
    return this.orderService.getOrder(id).pipe(tap((res) => {
      const transformedOrder = {
        ...res.data.getOrder,
        createdAt: formatDate(res.data.getOrder.createdAt),
        deliveryDate: formatDateWithoutTime(res.data.getOrder.deliveryDate),
        timeWindow: formatTime(res.data.getOrder.timeWindow),
      };
      ctx.patchState({order: transformedOrder})
    }));
  }
}
